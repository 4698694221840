import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'
import Cookies from 'js-cookie'

const request = axios.create({
  baseURL: 'https://oa.yjmedlab.com/prod-api',
  //请求超时时间
  timeout: 50000,
})

// 请求拦截
request.interceptors.request.use(
  (config) => {
    let token = Cookies.get('token')
    if (token) {
      config.headers['authorization'] = token
    }
    config.headers['Browser-Domain-Name'] = window.location.origin
    return config
  },
  (error) => {
    Toast.fail({
      message: error.message,
      forbidClick: true,
    })
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (
      error.response.data.resultCode == 401 ||
      error.response.data.resultCode == 402
    ) {
      router.push('/')
      Toast.fail({
        message: '请重新登录',
        forbidClick: true,
      })
    }
    return Promise.reject(error)
  }
)

export default request
