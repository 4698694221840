import instance from "@/utils/axios.js";
function getRequest(url, data) {
  return new Promise(function (resolve, reject) {
    instance({
      url: url,
      method: "get",
      data,
    }).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function postRequest(url, data) {
  return new Promise(function (resolve, reject) {
    instance({
      url: url,
      method: "post",
      data,
    }).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
// 获取验证码
const SmsCode = function (code) {
  return getRequest("h5/activity/wx/getOpenId?code="+code );
};

// 获取验证码
const add = function (data) {
  return postRequest("/wx/lucky/activity/add" , data );
};
// 获取验证码
const list = function (data) {
  return postRequest("/wx/lucky/activity/list" , data );
};
export default {
  SmsCode,
  add,
  list
};